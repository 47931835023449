import CompanyDetails from "./CompanyDetails";
import ContactUsForm from "./ContactUsForm";
import { useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useDataContext } from "./DataContext";
import DropDownImage from "./DropDownImage";
import { NOT_SET_VALUE } from "../js-components/globalVariations";
import ColoredTextPanel from "./ColoredTextPanel";

const Footer = () => {
    const { data, updateData, images, isEditMode } = useDataContext();
    const CONTACT_US = data.CONTACT_US;
    const COMPANY_DETAILS = data.COMPANY_DETAILS;

    let [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
    let [isEditBannerOpen, setIsEditBannerOpen] = useState(false);
    let [key, setKey] = useState("");
    let [newText, setNewText] = useState("");

    let openPopup = (key, text) => {
        setKey(key);
        setNewText(text);
        setIsEditPopupOpen(true);
    };

    let updateFooter = () => {
        let newData = { ...data };
        newData.CONTACT_US[key] = newText;
        updateData(newData);
        setIsEditPopupOpen(false);
    };

    let updateCompanyDetails = (key, value) => {
        let newData = { ...data };
        newData.COMPANY_DETAILS[key] = value;
        updateData(newData);
        setIsEditBannerOpen(false);
    };

    return (
        <footer
            id="contactUsSection"
            className="px-4 md:px-20 bg-gradient-to-b from-gray-30 to-white mt-22"
        >
            <div
                className="w-full h-full bg-contain bg-no-repeat bg-center-bottom pt-12 md:py-24 mb-10 md:mb-14"
                style={{
                    backgroundImage: `url(${COMPANY_DETAILS.footerMapImage})`,
                }}
            >
                <h3
                    onClick={
                        isEditMode
                            ? () => openPopup("name", CONTACT_US.name)
                            : undefined
                    }
                    className={`${
                        isEditMode ? "editable-content" : ""
                    } font-semibold text-left text text-base md:text-3xl`}
                    dangerouslySetInnerHTML={{
                        __html: CONTACT_US.name || NOT_SET_VALUE,
                    }}
                />
                <p
                    onClick={
                        isEditMode
                            ? () => openPopup("topText", CONTACT_US.topText)
                            : undefined
                    }
                    className={`${
                        isEditMode ? "editable-content" : ""
                    } text text-left my-5 w-4/6 md:w-3/6 text-sm md:text-xl leading-7 md:leading-10`}
                    dangerouslySetInnerHTML={{
                        __html: CONTACT_US.topText || NOT_SET_VALUE,
                    }}
                />
                <div className="flex gap-20 flex-col md:flex-row mt-10">
                    <ContactUsForm />
                    <CompanyDetails />
                </div>
                {isEditMode ? (
                    <button
                        onClick={() => setIsEditBannerOpen(true)}
                        className="float-end text-red-500 font-bold underline"
                    >
                        Change Banner Image
                    </button>
                ) : null}
            </div>
            <Popup
                className="my-popup"
                open={isEditPopupOpen}
                onClose={() => setIsEditPopupOpen(false)}
            >
                <div className="popup-body p-3 rounded bg-white">
                    <div className="mb-4">
                        <textarea
                            onChange={(event) =>
                                setNewText(event.currentTarget.value)
                            }
                            value={newText}
                            id="message"
                            name="message"
                            className="text text-sm md:text-xl bg-white w-full h-72 p-5 border rounded-xl"
                            rows="4"
                            placeholder="Type..."
                            required
                        ></textarea>
                    </div>
                    <ColoredTextPanel />
                    <div className="m-4 flex justify-end">
                        <button
                            onClick={() => setIsEditPopupOpen(false)}
                            className="px-5 py-2 text-blue-500 hover:opacity-80"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={() => updateFooter()}
                            className="px-5 py-2 bg-blue-500 text-white rounded hover:opacity-80"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Popup>
            <Popup
                className="my-popup"
                open={isEditBannerOpen}
                onClose={() => setIsEditBannerOpen(false)}
            >
                <div className="popup-body p-3 rounded bg-white">
                    <div className="w-full">
                        <div className="mb-4">
                            <DropDownImage
                                options={images}
                                onOptionSelected={(option) =>
                                    setNewText(option.image)
                                }
                            />
                        </div>

                        <div>
                            <div className="m-4 flex justify-end">
                                <button
                                    onClick={() => setIsEditBannerOpen(false)}
                                    className="px-5 py-2 text-blue-500 hover:opacity-80"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={() =>
                                        updateCompanyDetails(
                                            "footerMapImage",
                                            newText
                                        )
                                    }
                                    className="px-5 py-2 bg-blue-500 text-white rounded hover:opacity-80"
                                >
                                    Save Change
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        </footer>
    );
};
export default Footer;
