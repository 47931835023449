import { useEffect, useRef, useState } from "react";
import { Trash, PlusSquareFill } from 'react-bootstrap-icons';
import { IMAGE_ACCEPT_EXTENSIONS, MAIN_DOMAIN } from "../js-components/globalVariations";
import { callAPI, getFileExtension, imagesToOptions } from "../js-components/utilityFunctions";
import ReactLoading from 'react-loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDataContext } from "./DataContext";

const DropDownImage = (props) => {
    const { updateImages } = useDataContext();
    const options = props.options;
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [isUploadingFile, setIsUploadingFile] = useState(false);
    const [filter, setFilter] = useState("");
    const filterRef = useRef(null);

    useEffect(() => {
        isDropdownOpen && filterRef.current.focus();
    }, [isDropdownOpen]);

    let notify = (type, message) => {
        toast[type](message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
        setFilter("");
    };

    const selectOption = (option) => {
        props.onOptionSelected(option);
        setSelectedOption(option);
        setIsDropdownOpen(false);
    };

    const deleteImage = (event, option) => {
        event.stopPropagation();
        let res = window.confirm("Please check that this file is not used anywhere. This file will be permanently deleted from the server. Click OK button to continue");
        if(!res) return;
        let imageName = option.label;
        callAPI(
            MAIN_DOMAIN + "/api/images/delete-image-by-name/" + imageName,
            "",
            "DELETE",
            null,
            (http) => {
                const DATA = JSON.parse(http.responseText);
                notify("success", DATA.message);
                updateImages(imagesToOptions(DATA.images));
            },
            (http) => {
                const DATA = JSON.parse(http.responseText);
                notify("error", DATA.message);
            }
        );
    }

    const selectFileHandler = (event) => {
        const FILES = event.target.files;
        if(FILES.length === 0) return;
        const SELECTED_FILE = event.target.files[0];
        if(!IMAGE_ACCEPT_EXTENSIONS.includes(getFileExtension(SELECTED_FILE.name))) {
            notify("error", "Please select " + IMAGE_ACCEPT_EXTENSIONS.join(" or ") + " files");
            event.target.value = null;
            return;
        }
        setIsUploadingFile(true);
        const FORM_DATA = new FormData();
        FORM_DATA.append('file', SELECTED_FILE);
        callAPI(
            MAIN_DOMAIN + "/api/images/add-new-image",
            FORM_DATA,
            "POST",
            null,
            (http) => {
                const DATA = JSON.parse(http.responseText);
                notify("success", DATA.message);
                updateImages(imagesToOptions(DATA.images));
                setIsUploadingFile(false);
            },
            (http) => {
                const DATA = JSON.parse(http.responseText);
                notify("error", DATA.message);
                setIsUploadingFile(false);
            }
        )
    }

    return (
        <div className="container">
            <ToastContainer />
            <input onChange={(event) => selectFileHandler(event)} type="file" id="selectDropDownImageFile" accept=".png, .jpg" className="hidden" />
            <div className="w-10 flex items-center justify-center">
                {
                    isUploadingFile ?
                    <ReactLoading
                        type={"bubbles"}
                        color={"#177BD8"}
                        width={40}
                        height={40}
                    /> :
                    <label htmlFor="selectDropDownImageFile" className="cursor-pointer hover:opacity-80">
                        <PlusSquareFill color="#177BD8" size={30}/>
                    </label>
                }
            </div>
            <div className="dropdown">
                <div className="dropdown-toggle" onClick={toggleDropdown}>
                    {selectedOption && (
                        <img
                            src={selectedOption.image}
                            alt={selectedOption.label}
                            className="dropdown-option-image"
                        />
                    )}
                    <span className="dropdown-option-label">
                        {selectedOption
                            ? selectedOption.label
                            : "Select an image"}
                    </span>
                    <span className={`${isDropdownOpen ? "rotate-180" : ""} transition-transform dropdown-caret`}></span>
                </div>
                <div className={`dropdown-menu-container ${isDropdownOpen ? "block" : "hidden"}`}>
                    <input value={filter} ref={filterRef} onChange={(event) => setFilter(event.currentTarget.value.trim())} type="text" placeholder="Search..." />
                    <ul className={`dropdown-menu`}>
                        {options.filter(option => option.label.includes(filter)).map((option, index) => (
                            <li key={index} onClick={() => selectOption(option)}>
                                <button onClick={(event) => deleteImage(event, option)} className="text-red-500">
                                    <Trash className="mr-4" color="red" size={20} />
                                </button>
                                <img
                                    src={option.image}
                                    alt={option.label}
                                    className="dropdown-option-image"
                                />
                                <span className="dropdown-option-label">
                                    {option.label}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};
export default DropDownImage;
