import ShowMoreComponent from "./ShowMoreComponent";
import { useDataContext } from "./DataContext";

const OurServiceItem = (props) => {
    let { isEditMode } = useDataContext();

    return (
        <li className="shadow-green_5 rounded-xl flex flex-row w-full gap-1 py-4 px-4 md:px-2 bg-white mt-5">
            <div className="hidden md:block md:w-36 flex-shrink-0">
                <img className="" src={props.image} alt={props.text} />
            </div>
            <div className="grow">
                <div className="flex flex-row items-center gap-2">
                    <img
                        className="md:hidden w-16"
                        src={props.image}
                        alt={props.text}
                    />
                    <h4
                        className="font-semibold text text-base md:text-2xl"
                        dangerouslySetInnerHTML={{
                            __html: props.head,
                        }}
                    />
                </div>
                <ShowMoreComponent content={props.body} limit={100} />
                {isEditMode ? (
                    <button
                        onClick={() => props.deleteServicesItem(props.index)}
                        className="my-10 text-red-500 font-bold underline"
                    >
                        Delete Item
                    </button>
                ) : null}
            </div>
        </li>
    );
};
export default OurServiceItem;
