import React, { useState, useRef, useEffect } from 'react';

const ShowMoreComponent = ({ content, limit }) => {
    let [isExpanded, setIsExpanded] = useState(false);
    let [maxHeight, setMaxHeight] = useState('0px');
    let contentRef = useRef(null);
    useEffect(() => {
        if (contentRef.current) setMaxHeight(`${contentRef.current.scrollHeight}px`);
    }, [content]);
    const toggleIsExpanded = () => {
        setIsExpanded(!isExpanded);
        setMaxHeight(!isExpanded ? `${contentRef.current.scrollHeight}px` : `${limit}px`);
    };
    if (contentRef.current && contentRef.current.scrollHeight <= limit) return <p className='text mt-2 text-sm md:text-xl leading-7 md:leading-10' dangerouslySetInnerHTML={{ __html: content }}></p>
    let buttonLabel = isExpanded ? "Less" : "See More";

    return (
        <div>
            <div className='relative'>
                <p
                    ref={contentRef}
                    style={{
                        maxHeight: isExpanded ? maxHeight : limit,
                        overflow: 'hidden',
                        transition: 'max-height 0.4s ease',
                    }}
                    className="text mt-2 text-sm md:text-xl leading-7 md:leading-10"
                    dangerouslySetInnerHTML={{ __html: content }}
                />
                <div className={`bg-gradient-to-b from-transparent via-red-white to-white w-full h-full absolute bottom-0 ${isExpanded ? "hidden" : ""}`}></div>
            </div>
            <button className="font-semibold text-blue-500 float-right mr-5 text-sm md:text-xl" onClick={toggleIsExpanded}>{buttonLabel}</button>
        </div>
    );
};
export default ShowMoreComponent;