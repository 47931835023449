import { PATHS } from "./components/js-components/globalVariations";
import PrivateRoute from "./components/react-components/PrivateRoute";
import Admin from "./pages/admin-panel/Admin";
import Analytics from "./pages/admin-panel/Analytics";
import Contacts from "./pages/admin-panel/Contacts";
import Dashboard from "./pages/admin-panel/Dashboard";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";

const Routes = [
    { path: PATHS.root, element: <Home /> },
    { path: PATHS.editMode, element: <PrivateRoute><Home /></PrivateRoute> },
    { path: PATHS.login, element: <Login />},
    { 
        path: PATHS.admin.root + "/*", 
        element: <PrivateRoute><Admin /></PrivateRoute>,
        children: [
            { path: PATHS.admin.dashboard, element: <Dashboard /> },
            { path: PATHS.admin.contacts, element: <Contacts /> },
            { path: PATHS.admin.analytics, element: <Analytics /> }
        ]
    }
]
export default Routes;