import React from "react";

const Dashboard = () => {
    return (
        <div className="h-screen flex flex-col px-10 py-12 overflow-auto">
            <h2>Dashboard</h2>
        </div>
    );
};

export default Dashboard;
