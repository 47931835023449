import { useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useDataContext } from "./DataContext";
import DropDownImage from "./DropDownImage";
import { NOT_SET_VALUE } from "../js-components/globalVariations";
import ColoredTextPanel from "./ColoredTextPanel";

const DesktopNavBar = (props) => {
    const { data, updateData, images, isEditMode } = useDataContext();
    const ITEMS = props.ITEMS;
    const COMPANY_DETAILS = data.COMPANY_DETAILS;

    let [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
    let [isEditLogoOpen, setIsEditLogoOpen] = useState(false);
    let [newText, setNewText] = useState(COMPANY_DETAILS.name);

    let openPopup = (text) => {
        setNewText(text);
        setIsEditPopupOpen(true);
    };

    let updateNavBar = (key, value) => {
        let newData = { ...data };
        newData.COMPANY_DETAILS[key] = value;
        updateData(newData);
        setIsEditPopupOpen(false);
        setIsEditLogoOpen(false);
    };

    return (
        <nav
            className={`hidden md:block bg-blue-800.4 backdrop-blur z-20 fixed top-0 w-full border-b border-1 border-blue-700`}
        >
            <div
                className={`w-full h-full flex items-center flex-row justify-between`}
            >
                <div
                    className={`pl-20  flex flex-row w-full items-center py-5`}
                >
                    <div className="flex flex-row items-center gap-3">
                        <img
                            onClick={
                                isEditMode
                                    ? () => setIsEditLogoOpen(true)
                                    : undefined
                            }
                            alt="logo"
                            src={COMPANY_DETAILS.whiteLogo}
                            className={`${
                                isEditMode ? "editable-content" : ""
                            } md:h-auto h-5`}
                        />
                        <h3
                            onClick={
                                isEditMode
                                    ? () => openPopup(COMPANY_DETAILS.name)
                                    : undefined
                            }
                            className={`${
                                isEditMode ? "editable-content" : ""
                            } text-2xl whitespace-nowrap text-md select-none text-white font-medium font-family-Space-Grotesk`}
                            dangerouslySetInnerHTML={{
                                __html: COMPANY_DETAILS.name || NOT_SET_VALUE,
                            }}
                        />
                    </div>
                </div>
                <div
                    className={`pr-12 h-full text-white items-stretch flex gap-2 flex-row`}
                >
                    {ITEMS.map((item) => {
                        return (
                            <a
                                key={item.key}
                                className="text-left text-white hover:scale-105 text-xl font-medium nav-button relative py-4 rounded-md whitespace-nowrap select-none mx-8 cursor-pointer"
                                href={item.href}
                                dangerouslySetInnerHTML={{
                                    __html: item.text,
                                }}
                            />
                        );
                    })}
                </div>
            </div>
            <Popup
                className="my-popup"
                open={isEditPopupOpen}
                onClose={() => setIsEditPopupOpen(false)}
            >
                <div className="popup-body p-3 rounded bg-white">
                    <div className="mb-4">
                        <textarea
                            onChange={(event) =>
                                setNewText(event.currentTarget.value)
                            }
                            value={newText}
                            id="message"
                            name="message"
                            className="text text-sm md:text-xl bg-white w-full h-72 p-5 border rounded-xl"
                            rows="4"
                            placeholder="Type..."
                            required
                        ></textarea>
                    </div>
                    <ColoredTextPanel />
                    <div className="m-4 flex justify-end">
                        <button
                            onClick={() => setIsEditPopupOpen(false)}
                            className="px-5 py-2 text-blue-500 hover:opacity-80"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={() => updateNavBar("name", newText)}
                            className="px-5 py-2 bg-blue-500 text-white rounded hover:opacity-80"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Popup>
            <Popup
                className="my-popup"
                open={isEditLogoOpen}
                onClose={() => setIsEditLogoOpen(false)}
            >
                <div className="popup-body p-3 rounded bg-white">
                    <div className="w-full">
                        <div className="mb-4">
                            <DropDownImage
                                options={images}
                                onOptionSelected={(option) =>
                                    setNewText(option.image)
                                }
                            />
                        </div>

                        <div>
                            <div className="m-4 flex justify-end">
                                <button
                                    onClick={() => setIsEditLogoOpen(false)}
                                    className="px-5 py-2 text-blue-500 hover:opacity-80"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={() =>
                                        updateNavBar("whiteLogo", newText)
                                    }
                                    className="px-5 py-2 bg-blue-500 text-white rounded hover:opacity-80"
                                >
                                    Save Change
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        </nav>
    );
};
export default DesktopNavBar;
