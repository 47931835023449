import { useRoutes } from "react-router-dom";
import Routes from "./Routes";

const App = () => {
    const ROUTES = useRoutes(Routes);
    return ROUTES;
};

export default App;

