import locationIcon from "../../assets/images/location-icon.png";
import callIcon from "../../assets/images/call-icon.png";
import emailIcon from "../../assets/images/email-icon.png";
import { useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useDataContext } from "./DataContext";
import DropDownImage from "./DropDownImage";
import { NOT_SET_VALUE } from "../js-components/globalVariations";
import ColoredTextPanel from "./ColoredTextPanel";

const CompanyDetails = () => {
    const { data, updateData, images, isEditMode } = useDataContext();
    const COMPANY_DETAILS = data.COMPANY_DETAILS;

    let [isEditLogoOpen, setIsEditLogoOpen] = useState(false);
    let [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
    let [key, setKey] = useState("");
    let [newText, setNewText] = useState("");

    let openPopup = (key, text) => {
        setKey(key);
        setNewText(text);
        setIsEditPopupOpen(true);
    };

    let updateCompanyDetails = (key, value) => {
        let newData = { ...data };
        newData.COMPANY_DETAILS[key] = value;
        updateData(newData);
        setIsEditPopupOpen(false);
        setIsEditLogoOpen(false);
    };

    return (
        <div className="w-full md:w-3/6">
            <div className="flex flex-row items-center gap-3 items-start mb-8">
                <img
                    onClick={
                        isEditMode ? () => setIsEditLogoOpen(true) : undefined
                    }
                    alt="logo"
                    src={COMPANY_DETAILS.coloredLogo}
                    className={`${
                        isEditMode ? "editable-content" : ""
                    } h-7 md:h-auto`}
                />
                <h3
                    onClick={
                        isEditMode
                            ? () => openPopup("name", COMPANY_DETAILS.name)
                            : undefined
                    }
                    className={`${
                        isEditMode ? "editable-content" : ""
                    } text-xl md:text-2xl font-bold text text-left my-2`}
                    dangerouslySetInnerHTML={{
                        __html: COMPANY_DETAILS.name || NOT_SET_VALUE,
                    }}
                />
            </div>
            {COMPANY_DETAILS.address || isEditMode ? (
                <div className="my-4 flex flex-col items-start">
                    <div className="flex flex-row items-center gap-4">
                        <h3 className="text-base md:text-2xl font-medium text-center text">
                            Location
                        </h3>
                    </div>
                    <div className="my-2 flex flex-row items-start gap-2">
                        <img
                            alt="location"
                            src={locationIcon}
                            className="h-5 md:h-auto"
                        />
                        <p
                            onClick={
                                isEditMode
                                    ? () =>
                                          openPopup(
                                              "address",
                                              COMPANY_DETAILS.address
                                          )
                                    : undefined
                            }
                            className={`${
                                isEditMode ? "editable-content" : ""
                            } text-sm md:text-xl text text-left`}
                            dangerouslySetInnerHTML={{
                                __html:
                                    COMPANY_DETAILS.address || NOT_SET_VALUE,
                            }}
                        />
                    </div>
                </div>
            ) : null}
            {COMPANY_DETAILS.phoneNumber || isEditMode ? (
                <div className="my-4 flex flex-col items-start">
                    <div className="flex flex-row items-center gap-4">
                        <h3 className="text-base md:text-2xl font-medium text-center text">
                            Phone
                        </h3>
                    </div>
                    <div className="my-2 flex flex-row items-start gap-2">
                        <img
                            alt="call"
                            src={callIcon}
                            className="h-5 md:h-auto"
                        />
                        <p
                            onClick={
                                isEditMode
                                    ? () =>
                                          openPopup(
                                              "phoneNumber",
                                              COMPANY_DETAILS.phoneNumber
                                          )
                                    : undefined
                            }
                            className={`${
                                isEditMode ? "editable-content" : ""
                            } text-sm md:text-xl text text-left`}
                            dangerouslySetInnerHTML={{
                                __html:
                                    COMPANY_DETAILS.phoneNumber ||
                                    NOT_SET_VALUE,
                            }}
                        />
                    </div>
                </div>
            ) : null}
            {COMPANY_DETAILS.email || isEditMode ? (
                <div className="my-4 flex flex-col items-start">
                    <div className="flex flex-row items-center gap-4">
                        <h3 className="text-base md:text-2xl font-medium text-center text">
                            Email
                        </h3>
                    </div>
                    <div className="my-2 flex flex-row items-start gap-2">
                        <img
                            alt="email"
                            src={emailIcon}
                            className="h-5 md:h-auto"
                        />
                        <p
                            onClick={
                                isEditMode
                                    ? () =>
                                          openPopup(
                                              "email",
                                              COMPANY_DETAILS.email
                                          )
                                    : undefined
                            }
                            className={`${
                                isEditMode ? "editable-content" : ""
                            } text-sm md:text-xl text text-left`}
                            dangerouslySetInnerHTML={{
                                __html: COMPANY_DETAILS.email || NOT_SET_VALUE,
                            }}
                        />
                    </div>
                </div>
            ) : null}
            <Popup
                className="my-popup"
                open={isEditPopupOpen}
                onClose={() => setIsEditPopupOpen(false)}
            >
                <div className="popup-body p-3 rounded bg-white">
                    <div className="mb-4">
                        <textarea
                            onChange={(event) =>
                                setNewText(event.currentTarget.value)
                            }
                            value={newText}
                            id="message"
                            name="message"
                            className="text text-sm md:text-xl bg-white w-full h-72 p-5 border rounded-xl"
                            rows="4"
                            placeholder="Type..."
                            required
                        ></textarea>
                    </div>
                    <ColoredTextPanel />
                    <div className="m-4 flex justify-end">
                        <button
                            onClick={() => setIsEditPopupOpen(false)}
                            className="px-5 py-2 text-blue-500 hover:opacity-80"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={() => updateCompanyDetails(key, newText)}
                            className="px-5 py-2 bg-blue-500 text-white rounded hover:opacity-80"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Popup>
            <Popup
                className="my-popup"
                open={isEditLogoOpen}
                onClose={() => setIsEditLogoOpen(false)}
            >
                <div className="popup-body p-3 rounded bg-white">
                    <div className="w-full">
                        <div className="mb-4">
                            <DropDownImage
                                options={images}
                                onOptionSelected={(option) =>
                                    setNewText(option.image)
                                }
                            />
                        </div>

                        <div>
                            <div className="m-4 flex justify-end">
                                <button
                                    onClick={() => setIsEditLogoOpen(false)}
                                    className="px-5 py-2 text-blue-500 hover:opacity-80"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={() =>
                                        updateCompanyDetails(
                                            "coloredLogo",
                                            newText
                                        )
                                    }
                                    className="px-5 py-2 bg-blue-500 text-white rounded hover:opacity-80"
                                >
                                    Save Change
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        </div>
    );
};
export default CompanyDetails;
