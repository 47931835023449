import React, { useState } from "react";
import { callAPI, setCookie, setLogin } from '../../components/js-components/utilityFunctions';
import { MAIN_DOMAIN } from '../../components/js-components/globalVariations';
import { useNavigate } from "react-router-dom";
import ReactLoading from 'react-loading';

function Login() {
    let [email, setEmail] = useState("");
    let [password, setPassword] = useState("");
    let [errorMessage, setErrorMessage] = useState("");
    let [sending, setSending] = useState(false);
    let NAVIGATE = useNavigate();

    let submitHandler = (event) => {
        event.preventDefault();
        const DATA_TO_SEND = `email=${email}&password=${password}`;
        setErrorMessage("");
        setSending(true);
        callAPI(
            MAIN_DOMAIN + "/api/login",
            DATA_TO_SEND,
            "POST",
            "application/x-www-form-urlencoded",
            (http) => {
                setSending(false);
                const DATA = JSON.parse(http.responseText);
                setLogin(DATA.token_id);
                setCookie("role", DATA.user.role);
                NAVIGATE(-1);
            },
            (http) => {
                setSending(false);
                const DATA = JSON.parse(http.responseText);
                setErrorMessage(DATA.message);
            }
        );
    }

    return (
        <div className="bg-gradient-to-br from-blue-100 to-blue-900 min-h-screen flex flex-col justify-center items-center">
            <div className="bg-white rounded-lg shadow-lg p-8 max-w-md">
                <h1 className="text-4xl font-bold text-center text-blue-900 mb-8">
                    Sign in
                </h1>
                <form onSubmit={submitHandler} className="space-y-6">
                    <div>
                        <label
                            className="block text font-bold mb-2"
                            htmlFor="email"
                        >
                            Email
                        </label>
                        <input
                            onChange={(event) =>
                                setEmail(event.currentTarget.value)
                            }
                            value={email}
                            className="w-full px-4 py-2 rounded-lg border border-gray-400"
                            id="email"
                            name="email"
                            type="email"
                            required
                        />
                    </div>
                    <div>
                        <label
                            className="block text font-bold mb-2"
                            htmlFor="password"
                        >
                            Password
                        </label>
                        <input
                            onChange={(event) =>
                                setPassword(event.currentTarget.value)
                            }
                            value={password}
                            className="w-full px-4 py-2 rounded-lg border border-gray-400"
                            id="password"
                            name="password"
                            type="password"
                            required
                        />
                    </div>
                    <div>
                        <button
                            disabled={sending}
                            type="submit"
                            className="w-full bg-blue-900 hover:bg-blue-700 text-white font-bold h-10 rounded-lg flex items-center justify-center"
                        >
                            {
                                sending ? <ReactLoading type={"bubbles"} color={"#ffffff"} width={40} height={40}/> : <span>Log In</span>
                            }
                        </button>
                    </div>
                    <div>
                        {
                            errorMessage ? <p className="text-sm text-red-500 text-center font-bold">{errorMessage}</p> : null
                        }
                    </div>
                </form>
            </div>
        </div>
    );
}
export default Login;