import OurServiceItem from "./OurServiceItem";
import { useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useDataContext } from "./DataContext";
import DropDownImage from "./DropDownImage";
import { NOT_SET_VALUE } from "../js-components/globalVariations";
import ColoredTextPanel from "./ColoredTextPanel";

const SectionOurServices = () => {
    const { data, updateData, images, isEditMode } = useDataContext();
    const SERVICES = data.SERVICES;

    let [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
    let [isAddNewPopupOpen, setIsAddNewPopupOpen] = useState(false);
    let [key, setKey] = useState("");
    let [newText, setNewText] = useState("");

    let openPopup = (key, text) => {
        setKey(key);
        setNewText(text);
        setIsEditPopupOpen(true);
    };

    let updateServices = () => {
        let newData = { ...data };
        newData.SERVICES[key] = newText;
        updateData(newData);
        setIsEditPopupOpen(false);
    };

    let deleteServicesItem = (index) => {
        let res = window.confirm("This item will be deleted !");
        if (!res) return;
        let newData = { ...data };
        newData.SERVICES.items.splice(index, 1);
        updateData(newData);
    };

    let [newItemImage, setNewItemImage] = useState("");
    let [newItemTitle, setNewItemTitle] = useState("");
    let [newItemBody, setNewItemBody] = useState("");
    let addNewItem = (event) => {
        event.preventDefault();
        const NEW_ITEM = {
            image: newItemImage,
            head: newItemTitle,
            body: newItemBody,
        };
        let newData = { ...data };
        newData.SERVICES.items.push(NEW_ITEM);
        updateData(newData);
        setIsAddNewPopupOpen(false);
    };

    return (
        <section id="ourServicesSection" className="pt-20 mx-4 md:mx-20">
            <h3
                onClick={
                    isEditMode
                        ? () => openPopup("name", SERVICES.name)
                        : undefined
                }
                className={`${
                    isEditMode ? "editable-content" : ""
                } font-semibold text-center text text-base md:text-3xl`}
                dangerouslySetInnerHTML={{
                    __html: SERVICES.name || NOT_SET_VALUE,
                }}
            />
            <p
                onClick={
                    isEditMode
                        ? () => openPopup("topText", SERVICES.topText)
                        : undefined
                }
                className={`${
                    isEditMode ? "editable-content" : ""
                } text text-center my-5 md:mx-10 text-sm md:text-xl leading-7 md:leading-10`}
                dangerouslySetInnerHTML={{
                    __html: SERVICES.topText || NOT_SET_VALUE,
                }}
            />
            <ul className="mt-5 flex flex-wrap items-center justify-center">
                {SERVICES.items.map((item, index) => (
                    <OurServiceItem
                        deleteServicesItem={deleteServicesItem}
                        index={index}
                        key={index}
                        image={item.image}
                        head={item.head}
                        body={item.body}
                    />
                ))}
                {isEditMode ? (
                    <button
                        onClick={() => setIsAddNewPopupOpen(true)}
                        className="my-10 text-red-500 font-bold underline"
                    >
                        + Add New Item
                    </button>
                ) : null}
            </ul>
            <Popup
                className="my-popup"
                open={isEditPopupOpen}
                onClose={() => setIsEditPopupOpen(false)}
            >
                <div className="popup-body p-3 rounded bg-white">
                    <div className="mb-4">
                        <textarea
                            onChange={(event) =>
                                setNewText(event.currentTarget.value)
                            }
                            value={newText}
                            id="message"
                            name="message"
                            className="text text-sm md:text-xl bg-white w-full h-72 p-5 border rounded-xl"
                            rows="4"
                            placeholder="Type..."
                            required
                        ></textarea>
                    </div>
                    <ColoredTextPanel />
                    <div className="m-4 flex justify-end">
                        <button
                            onClick={() => setIsEditPopupOpen(false)}
                            className="px-5 py-2 text-blue-500 hover:opacity-80"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={() => updateServices()}
                            className="px-5 py-2 bg-blue-500 text-white rounded hover:opacity-80"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Popup>
            <Popup
                className="my-popup"
                open={isAddNewPopupOpen}
                onClose={() => setIsAddNewPopupOpen(false)}
            >
                <div className="popup-body p-3 rounded bg-white">
                    <form className="w-full">
                        <div className="mb-4">
                            <DropDownImage
                                options={images}
                                onOptionSelected={(option) =>
                                    setNewItemImage(option.image)
                                }
                            />
                        </div>

                        <div className="mb-4">
                            <input
                                onChange={(event) =>
                                    setNewItemTitle(event.currentTarget.value)
                                }
                                value={newItemTitle}
                                type="text"
                                className="text text-sm md:text-xl bg-white w-full p-5 border rounded-xl"
                                placeholder="Title ..."
                                required
                            />
                        </div>

                        <div className="mb-4">
                            <textarea
                                onChange={(event) =>
                                    setNewItemBody(event.currentTarget.value)
                                }
                                value={newItemBody}
                                type="text"
                                className="text text-sm md:text-xl bg-white w-full h-48 p-5 border rounded-xl"
                                rows="3"
                                placeholder="Body ..."
                                required
                            ></textarea>
                        </div>

                        <ColoredTextPanel />

                        <div>
                            <div className="m-4 flex justify-end">
                                <button
                                    onClick={() => setIsAddNewPopupOpen(false)}
                                    className="px-5 py-2 text-blue-500 hover:opacity-80"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={(event) => addNewItem(event)}
                                    type="submit"
                                    className="px-5 py-2 bg-blue-500 text-white rounded hover:opacity-80"
                                >
                                    Add Item
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Popup>
        </section>
    );
};
export default SectionOurServices;
