import { callAPI, downloadObjectAsTextFile, getFileExtension } from "../js-components/utilityFunctions";
import { useDataContext } from "./DataContext";
import { useState } from "react";
import { CONTENT_IMPORT_ACCEPT_EXTENSIONS, MAIN_DOMAIN } from "../js-components/globalVariations";
import ReactLoading from 'react-loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const EditModeController = () => {
    const { data, updateData } = useDataContext();
    let [isDeploying, setIsDeploying] = useState(false);
    let notify = (type, message) => {
        toast[type](message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }
    
    let deployDataToServe = () => {
        setIsDeploying(true);
        const FORM_DATA = new FormData();
        const JSON_STRING = JSON.stringify(data, null, '\t');
        const BOLB = new Blob([JSON_STRING], { type: 'text/plain' });
        FORM_DATA.append('data', BOLB, "data.json");
        callAPI(
            MAIN_DOMAIN + "/api/setData",
            FORM_DATA,
            "POST",
            null,
            (http) => {
                setIsDeploying(false);
                notify("success", "Data deployed successfully");
            },
            (http) => {
                setIsDeploying(false);
                const DATA = JSON.parse(http.responseText);
                notify("error", DATA.message);
            }
        )
    }

    let selectFileHandler = (event) => {
        const FILES = event.target.files;
        if(FILES.length === 0) return;
        const SELECTED_FILE = event.target.files[0];
        if(!CONTENT_IMPORT_ACCEPT_EXTENSIONS.includes(getFileExtension(SELECTED_FILE.name))) {
            notify("error", "Please select " + CONTENT_IMPORT_ACCEPT_EXTENSIONS.join(" or ") + " files");
            event.target.value = null;
            return;
        }
        const READER = new FileReader();
        READER.onload = function(e) {
            const CONTENT = e.target.result;
            try {
                const JSON_CONTENT = JSON.parse(CONTENT);
                updateData(JSON_CONTENT);
            } catch (error) {
                notify("error", "Error in parse data");
            }
        };
        READER.readAsText(SELECTED_FILE);
        event.target.value = null;
    }

    return (
        <div className="sticky bottom-2.5 left-2.5 z-50 flex flex-row items-stretch">
            <ToastContainer />
            <input onChange={(event) => selectFileHandler(event)} type="file" id="importContentInput" accept=".json" className="hidden" />
            <button
                className="hover:opacity-90 mx-1 md:mx-2 rounded-lg bg-blue-900 text-white shadow-blue-900-center"
            >
                <label htmlFor="importContentInput" className="px-4 py-1 md:px-6 md:py-2 cursor-pointer">
                    <span className="text-xs md:text-sm">Import</span>
                </label>
            </button>
            <button
                onClick={() => downloadObjectAsTextFile(data, "data.json")}
                className="hover:opacity-90 mx-1 md:mx-2 px-4 py-1 md:px-6 md:py-2 rounded-lg bg-blue-900 text-white shadow-blue-900-center"
            >
                <span className="text-xs md:text-sm">Export</span>
            </button>
            <button
                onClick={() => deployDataToServe()}
                className="hover:opacity-90 mx-1 md:mx-2 px-4 py-1 md:px-6 md:py-2 rounded-lg bg-blue-900 text-white shadow-blue-900-center"
            >
                {
                    isDeploying ? 
                    <div className="flex flex-row items-center gap-2">
                        <span className="text-xs md:text-sm">Deploying</span>
                        <ReactLoading type={"bubbles"} color={"#ffffff"} width={23} height={23}/>
                    </div> : <span className="text-xs md:text-sm">Deploy to server</span>
                }
            </button>
        </div>
    );
};
export default EditModeController;
