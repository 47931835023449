import { ROLES, COOKIE_EXPIRE_TIME, IMAGES_DIR, PATHS } from "./globalVariations";

export function callAPI(url, data, method, content_type, successfull_callback, error_callback, responseType = null) {
	let http = new XMLHttpRequest();
	http.open(method, url, true);
    http.setRequestHeader("Accept", "application/json");
    if(content_type) http.setRequestHeader("Content-Type", content_type);
    http.setRequestHeader("Authorization", "Bearer " + getCookie("token_id"));
    if(responseType) http.responseType = responseType;
	http.send(data);
	http.onreadystatechange = function() {
        if(http.readyState === http.DONE) {
            if(200 <= http.status && http.status < 300) {
                if(successfull_callback) successfull_callback(http);
            } else if([401].includes(http.status)) {
                setLogout();
                window.location.href = PATHS.login;
            } else if(http.status === 403) {
                alert ("Access denied");
            } else {
                if(error_callback) error_callback(http);
            }
        }
	}
}

export function getCookie(key) {
    const name = key + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return null;
}

export function downloadObjectAsTextFile (m_obj, name) {
    const JSON_STRING = JSON.stringify(m_obj, null, '\t');
    const BOLB = new Blob([JSON_STRING], { type: 'text/plain' });

    const LINK = document.createElement('a');
    LINK.href = URL.createObjectURL(BOLB);
    LINK.download = name;
    document.body.appendChild(LINK);
    LINK.click();
    document.body.removeChild(LINK);
};

export function setCookie(key, value, exDays = COOKIE_EXPIRE_TIME) {
    const d = new Date();
    d.setTime(d.getTime() + (exDays * 24 * 60 * 60 * 1000));
    const expires = "expires="+d.toUTCString();
    document.cookie = key + "=" + value + ";" + expires + ";path=/";
}

export function isAdmin() {
    return getCookie("role") === ROLES.ADMIN;
}

export function setLogin(token_id = null) {
    if(token_id) setCookie("token_id", token_id);
}

export function setLogout() {
    setCookie("token_id", null);
    setCookie("role", null);
}

export function isLogin() {
    return getCookie("token_id") != null;
}

export function imagesToOptions(images) {
    return images.map(img => {return {label: img, image: IMAGES_DIR + img}});
}

export function getFileExtension(fileName) {
    return fileName.split('.').pop().toLowerCase();
}