import { useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { useDataContext } from './DataContext';
import DropDownImage from './DropDownImage';
import { NOT_SET_VALUE } from '../js-components/globalVariations';
import ColoredTextPanel from './ColoredTextPanel';

const Header = () => {
    const { data, updateData, images, isEditMode } = useDataContext();
    const COMPANY_DETAILS = data.COMPANY_DETAILS;
    const HEADER = data.HEADER;

    let [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
    let [newText, setNewText] = useState(HEADER.topText);
    let [isEditBannerOpen, setIsEditBannerOpen] = useState(false);

    let updateHeader = (key, value) => {
        let newData = {...data};
        newData.HEADER[key] = value;
        updateData(newData);
        setIsEditPopupOpen(false);
    }

    let [newImage, setNewImage] = useState("");
    let updateCompanyDetails = (key, value) => {
        let newData = {...data};
        newData.COMPANY_DETAILS[key] = value;
        updateData(newData);
        setIsEditBannerOpen(false);
    }
    
    return (
        <header
            className="relative bg-cover bg-no-repeat bg-[right_13%_top] h-[350px] md:h-[700px]"
            style={{ backgroundImage: `url(${COMPANY_DETAILS.bannerImage})` }}
        >
            <div className='h-full w-full'>
                <div className="pl-4 md:pl-20 h-full flex flex-col items-start justify-center">
                    {
                        isEditMode ? <button onClick={ () => setIsEditBannerOpen(true) } className='text-red-500 font-bold underline'>Change Banner Image</button> : null
                    }
                    <p 
                        onClick={ isEditMode ? () => setIsEditPopupOpen(true) : undefined }
                        className={`${isEditMode ? "editable-content" : ""} font-family-Rubik md:w-3/6 w-5/6 text-white font-semibold text-sm md:text-3xl leading-33 md:leading-75 [text-shadow:_0_4px_4px_rgb(0_0_0_/_80%)]`}
                        dangerouslySetInnerHTML={{ __html: HEADER.topText || NOT_SET_VALUE }}
                    />
                </div>
            </div>
            <Popup className='my-popup' open={isEditPopupOpen} onClose={() => setIsEditPopupOpen(false)}>
                <div className='popup-body p-3 rounded bg-white'>
                    <div className="mb-4">
                        <textarea
                            onChange={(event) => setNewText(event.currentTarget.value)}
                            value={newText}
                            id="message"
                            name="message"
                            className="text text-sm md:text-xl bg-white w-full h-72 p-5 border rounded-xl"
                            rows="4"
                            placeholder="Type..."
                            required
                        ></textarea>
                    </div>
                    <ColoredTextPanel />
                    <div className='m-4 flex justify-end'>
                        <button onClick={() => setIsEditPopupOpen(false) } className='px-5 py-2 text-blue-500 hover:opacity-80'>Cancel</button>
                        <button onClick={() => updateHeader("topText", newText)} className='px-5 py-2 bg-blue-500 text-white rounded hover:opacity-80'>Save</button>
                    </div>
                </div>
            </Popup>
            <Popup className="my-popup" open={isEditBannerOpen} onClose={() => setIsEditBannerOpen(false)}>
                <div className="popup-body p-3 rounded bg-white">
                    <div
                        className="w-full"
                    >
                        <div className="mb-4">
                            <DropDownImage options={images} onOptionSelected={(option) => setNewImage(option.image)} />
                        </div>

                        <div>
                            <div className='m-4 flex justify-end'>
                                <button onClick={() => setIsEditBannerOpen(false)} className='px-5 py-2 text-blue-500 hover:opacity-80'>Cancel</button>
                                <button onClick={() => updateCompanyDetails("bannerImage", newImage)} className='px-5 py-2 bg-blue-500 text-white rounded hover:opacity-80'>Save Change</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        </header>
    );
};
export default Header;
