import { useState } from "react";
import MenuIcon from "../../assets/images/components/MenuIcon";
import { NOT_SET_VALUE } from "../js-components/globalVariations";
import { useDataContext } from "./DataContext";

const MobileNavBar = (props) => {
    const { data } = useDataContext();
    const ITEMS = props.ITEMS;
    const COMPANY_DETAILS = data.COMPANY_DETAILS;
    let [isMenuOpen, setIsMenuOpen] = useState(false);
    let toggleNavbar = () => setIsMenuOpen(!isMenuOpen);

    return (
        <nav
            className={`${
                isMenuOpen ? "h-full" : ""
            } block md:hidden bg-blue-800.4 backdrop-blur z-20 fixed top-0 w-full border-b border-1 border-blue-700`}
            onClick={(event) => {
                isMenuOpen &&
                    event.target === event.currentTarget &&
                    setIsMenuOpen(false);
            }}
        >
            <div className={`pl-4 flex flex-row w-full items-center py-5`}>
                <button
                    onClick={toggleNavbar}
                    className="text-white mr-4 scale-150"
                >
                    <MenuIcon />
                </button>
                <div className="flex flex-row items-center gap-3">
                    <img
                        alt="logo"
                        src={COMPANY_DETAILS.whiteLogo}
                        className="h-5"
                    />
                    <h3
                        className="whitespace-nowrap text-md select-none text-white font-medium font-family-Space-Grotesk"
                        dangerouslySetInnerHTML={{
                            __html: COMPANY_DETAILS.name || NOT_SET_VALUE,
                        }}
                    />
                </div>
            </div>
            <div
                className={`${
                    isMenuOpen ? "w-4/5" : "w-0"
                } bg-green-5 overflow-hidden top-0 h-screen absolute h-full transition-width duration-300 ease-in-out flex items-flex-start flex-col justify-between`}
            >
                <div
                    className={`bg-blue-900 pl-4 flex flex-row w-full items-center py-5`}
                >
                    <button
                        onClick={toggleNavbar}
                        className="text-white mr-4 scale-150"
                    >
                        <MenuIcon />
                    </button>
                    <div className="flex flex-row items-center gap-3">
                        <img
                            alt="logo"
                            src={COMPANY_DETAILS.whiteLogo}
                            className="h-5"
                        />
                        <h3
                            className="whitespace-nowrap text-md select-none text-white font-medium font-family-Space-Grotesk"
                            dangerouslySetInnerHTML={{
                                __html: COMPANY_DETAILS.name || NOT_SET_VALUE,
                            }}
                        />
                    </div>
                </div>
                <div
                    className={`mx-4 h-full text-white items-stretch py-5 flex-col gap-2 flex`}
                >
                    {ITEMS.map((item) => {
                        return (
                            <a
                                key={item.key}
                                onClick={toggleNavbar}
                                className="py-4 hover:bg-green-20 text-left text-blue-900 font-medium nav-button relative px-4 rounded-md whitespace-nowrap select-none cursor-pointer"
                                href={item.href}
                                dangerouslySetInnerHTML={{
                                    __html: item.text,
                                }}
                            />
                        );
                    })}
                </div>
            </div>
        </nav>
    );
};
export default MobileNavBar;
