import { Navigate } from "react-router-dom";
import { PATHS } from "../js-components/globalVariations";
import { isLogin, isAdmin } from "../js-components/utilityFunctions";

const PrivateRoute = ({children}) => {
    return (
        <>
            {
                isLogin() && isAdmin() ? children : <Navigate to={PATHS.login} />
            }
        </>
    );
}
export default PrivateRoute;