import React, { createContext, useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { PATHS } from "../js-components/globalVariations";

const DataContext = createContext();

const DataContextProvider = ({ children }) => {

    const LOCATION = useLocation();

    let [data, setData] = useState(null);
    let [images, setImages] = useState([]);
    let [isEditMode, setIsEditMode] = useState();

    let updateData = (newData) => {
        setData(newData);
    };

    let updateImages = (newImages) => {
        setImages(newImages);
    }

    let updateIsEditMode = (newIsEditMode) => {
        setIsEditMode(newIsEditMode);
    }

    useEffect(() => {
        updateIsEditMode(LOCATION.pathname === PATHS.editMode);
    }, [LOCATION])

    return (
        <DataContext.Provider value={{ data, updateData, images, updateImages, isEditMode, updateIsEditMode }}>
            {
                children
            }
        </DataContext.Provider>
    );
};

const useDataContext = () => {
    return useContext(DataContext);
};

export { DataContextProvider, useDataContext };
