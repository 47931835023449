export const MAIN_DOMAIN = process.env.REACT_APP_API_URL; // "http://127.0.0.1:8000"; // #TODO : in production change to website domain
export const IMAGES_DIR = "./contents/images/";
export const NOT_SET_VALUE = "-Not set-";
export const ROLES = {
    ADMIN: "admin",
    USER: "user"
}
export const COOKIE_EXPIRE_TIME = 1; // in day
export const IMAGE_ACCEPT_EXTENSIONS = ["png", "jpg"];
export const CONTENT_IMPORT_ACCEPT_EXTENSIONS = ["json"];
export const PATHS = {
    root: "/",
    editMode: "/edit",
    login: "/login",
    admin: {
        root: "/admin",
        contacts: "contacts",
        analytics: "analytics",
        dashboard: "dashboard"
    }
}