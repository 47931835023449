import { v4 as uuidv4 } from "uuid";
import { useDataContext } from "./DataContext";
import { useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { NOT_SET_VALUE } from "../js-components/globalVariations";
import ColoredTextPanel from "./ColoredTextPanel";

const SectionAboutUs = () => {
    const { data, updateData, isEditMode } = useDataContext();
    const ABOUT_US = data.ABOUT_US;

    let [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
    let [newText, setNewText] = useState("");
    let [key, setKey] = useState("");

    let openPopup = (newKey, text) => {
        setKey(newKey);
        setNewText(text);
        setIsEditPopupOpen(true);
    };

    let updateAboutUs = () => {
        let newData = { ...data };
        if (key === "items") newData.ABOUT_US.items.push(newText);
        else newData.ABOUT_US[key] = newText;
        updateData(newData);
        setIsEditPopupOpen(false);
    };

    let deleteAboutUsItem = (index) => {
        let res = window.confirm("This item will be deleted !");
        if (!res) return;
        let newData = { ...data };
        newData.ABOUT_US.items.splice(index, 1);
        updateData(newData);
    };

    return (
        <section
            id="aboutUsSection"
            className="relative z-10 -mt-32 pt-20 mx-4 md:mx-20"
        >
            <div className="w-full bg-white shadow-green_100 rounded-2xl p-6 md:p-16">
                <h3
                    onClick={
                        isEditMode
                            ? () => openPopup("name", ABOUT_US.name)
                            : undefined
                    }
                    className={`${
                        isEditMode ? "editable-content" : ""
                    } font-semibold text-left text text-sm md:text-3xl`}
                    dangerouslySetInnerHTML={{
                        __html: ABOUT_US.name || NOT_SET_VALUE,
                    }}
                />
                <div className="mt-5">
                    <p
                        onClick={
                            isEditMode
                                ? () => openPopup("topText", ABOUT_US.topText)
                                : undefined
                        }
                        className={`${
                            isEditMode ? "editable-content" : ""
                        } text text-left text-xs md:text-xl leading-7 md:leading-10`}
                        dangerouslySetInnerHTML={{
                            __html: ABOUT_US.topText || NOT_SET_VALUE,
                        }}
                    />
                    <ol className="text list-decimal pl-4 md:pl-6 my-2">
                        {ABOUT_US.items.map((item) => {
                            return (
                                <li
                                    key={uuidv4()}
                                    className="my-1 md:my-4 text-xs md:text-xl leading-7 md:leading-10"
                                >
                                    <mark
                                        className="text pr-1 bg-green-20 font-semibold"
                                        dangerouslySetInnerHTML={{
                                            __html: item,
                                        }}
                                    />
                                    {isEditMode ? (
                                        <button
                                            onClick={() =>
                                                deleteAboutUsItem(
                                                    ABOUT_US.items.indexOf(item)
                                                )
                                            }
                                            className="mx-2 text-red-500 font-bold underline"
                                        >
                                            Delete
                                        </button>
                                    ) : null}
                                </li>
                            );
                        })}
                        {isEditMode ? (
                            <button
                                onClick={() => openPopup("items", "")}
                                className="text-red-500 font-bold underline"
                            >
                                + Add New Item
                            </button>
                        ) : null}
                    </ol>
                    <p
                        onClick={
                            isEditMode
                                ? () =>
                                      openPopup(
                                          "bottomText",
                                          ABOUT_US.bottomText
                                      )
                                : undefined
                        }
                        className={`${
                            isEditMode ? "editable-content" : ""
                        } text text-left text-xs md:text-xl leading-7 md:leading-10`}
                        dangerouslySetInnerHTML={{
                            __html: ABOUT_US.bottomText || NOT_SET_VALUE,
                        }}
                    />
                </div>
            </div>
            <Popup
                className="my-popup"
                open={isEditPopupOpen}
                onClose={() => setIsEditPopupOpen(false)}
            >
                <div className="popup-body p-3 rounded bg-white">
                    <div className="mb-4">
                        <textarea
                            onChange={(event) =>
                                setNewText(event.currentTarget.value)
                            }
                            value={newText}
                            id="message"
                            name="message"
                            className="text text-sm md:text-xl bg-white w-full h-72 p-5 border rounded-xl"
                            rows="4"
                            placeholder="Type..."
                            required
                        ></textarea>
                    </div>
                    <ColoredTextPanel />
                    <div className="m-4 flex justify-end">
                        <button
                            onClick={() => setIsEditPopupOpen(false)}
                            className="px-5 py-2 text-blue-500 hover:opacity-80"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={() => updateAboutUs()}
                            className="px-5 py-2 bg-blue-500 text-white rounded hover:opacity-80"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Popup>
        </section>
    );
};
export default SectionAboutUs;
