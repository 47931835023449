import React from "react";
const MenuIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="21" height="2.33255" rx="1.16627" transform="matrix(-1 0 0 1 24.5 12.834)" fill="white"/>
            <rect width="14" height="2.33255" rx="1.16627" transform="matrix(-1 0 0 1 17.5 4.66699)" fill="white"/>
            <rect width="17.5" height="2.33255" rx="1.16627" transform="matrix(-1 0 0 1 21 21)" fill="white"/>
        </svg>
    );
}
export default MenuIcon;