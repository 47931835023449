import React, { useEffect } from 'react';
import NavBar from '../../components/react-components/NavBar';
import Header from '../../components/react-components/Header';
import SectionAboutUs from '../../components/react-components/SectionAboutUs';
import SectionApplications from '../../components/react-components/SectionApplications';
import SectionOurServices from '../../components/react-components/SectionOurServices';
import Footer from '../../components/react-components/Footer';
import { callAPI, imagesToOptions } from '../../components/js-components/utilityFunctions';
import { MAIN_DOMAIN } from '../../components/js-components/globalVariations';
import EditModeController from '../../components/react-components/EditModeController';
import { useDataContext } from '../../components/react-components/DataContext';

function Home() {
    let { data, updateData, updateImages, isEditMode } = useDataContext();
    useEffect(() => {
        let min = 0;
        let max = 1000;
        const VERSION = Math.floor(Math.random() * (max - min)) + min;
        const fetchData = async () => {
            try {
                const response = await fetch('/contents/data.json?version=' + VERSION);
                const jsonData = await response.json();
                updateData(jsonData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        const fetchImages = async () => {
            callAPI(
                MAIN_DOMAIN + "/api/images", 
                "", 
                "GET", 
                "application/x-www-form-urlencoded",
                (http) => {
                    const DATA = JSON.parse(http.responseText);
                    updateImages(imagesToOptions(DATA.images));
                }
            );
        };
        
        isEditMode && fetchImages();
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditMode]);
    if(!data) return;
    return (
        <div className='bg-green-10'>
            <NavBar />
            <Header />
            <SectionAboutUs />
            <SectionApplications />
            <SectionOurServices />
            <Footer />
            {
                isEditMode ? 
                <EditModeController />
                : null
            }
        </div>
    );
}
export default Home;