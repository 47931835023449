import ReactLoading from 'react-loading';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { useState } from 'react';
import { callAPI } from '../js-components/utilityFunctions';
import { MAIN_DOMAIN } from '../js-components/globalVariations';

const ContactUsForm = () => {
    const STATUS = {
        NOT_SENT: "not-send",
        SENDING: "sending",
        SENT: "sent"
    };
    let [company, setCompany] = useState("");
    let [name, setName] = useState("");
    let [email, setEmail] = useState("");
    let [phone, setPhone] = useState("");
    let [message, setMessage] = useState("");
    let [hCaptchaResponse, setHCaptchaResponse] = useState("");
    let [isHuman, setIsHuman] = useState(false);
    let [formState, setFormState] = useState(STATUS.NOT_SENT);
    let handleVerificationSuccess = (token, ekey) => {
        setHCaptchaResponse(token);
        setIsHuman(true);
    }
    let submitHandler = (event) => {
        event.preventDefault();
        const DATA_TO_SEND = `company=${company}&name=${name}&email=${email}&phone=${phone}&message=${message}&h-captcha-response=${hCaptchaResponse}`;
        setFormState(STATUS.SENDING);
        callAPI(
            MAIN_DOMAIN + "/api/contact-us",
            DATA_TO_SEND,
            "POST",
            "application/x-www-form-urlencoded",
            (http) => {
                setFormState(STATUS.SENT);
            },
            (http) => {
                const DATA = JSON.parse(http.responseText);
                setFormState(STATUS.NOT_SENT);
                alert(DATA.message);  
            }
        );
    }

    return (
        <div className="w-full md:w-3/6 flex justify-center">
            <form
                onSubmit={submitHandler}
                className="w-full"
            >
                <div className="mb-4">
                    <input
                        onChange={(event) =>
                            setCompany(event.currentTarget.value)
                        }
                        value={company}
                        type="text"
                        id="company"
                        name="company"
                        className="text text-sm md:text-xl bg-white w-full p-5 border rounded-xl"
                        placeholder="Your Company Name"
                    />
                </div>
                
                <div className="mb-4">
                    <input
                        onChange={(event) =>
                            setName(event.currentTarget.value)
                        }
                        value={name}
                        type="text"
                        id="name"
                        name="name"
                        className="text text-sm md:text-xl bg-white w-full p-5 border rounded-xl"
                        placeholder="Your Name"
                        required
                    />
                </div>

                <div className="mb-4">
                    <input
                        onChange={(event) =>
                            setEmail(event.currentTarget.value)
                        }
                        value={email}
                        type="email"
                        id="email"
                        name="email"
                        className="text text-sm md:text-xl bg-white w-full p-5 border rounded-xl"
                        placeholder="Your Email address"
                        required
                    />
                </div>

                <div className="mb-4">
                    <input
                        onChange={(event) =>
                            setPhone(event.currentTarget.value)
                        }
                        value={phone}
                        type="tel"
                        id="phone"
                        name="phone"
                        className="text text-sm md:text-xl bg-white w-full p-5 border rounded-xl"
                        placeholder="Your phone Number"
                        required
                    />
                </div>

                <div className="mb-4">
                    <textarea
                        onChange={(event) =>
                            setMessage(event.currentTarget.value)
                        }
                        value={message}
                        id="message"
                        name="message"
                        className="text text-sm md:text-xl bg-white w-full h-72 p-5 border rounded-xl"
                        rows="4"
                        placeholder="Message..."
                        required
                    ></textarea>
                </div>

                <div
                    className={`mb-4 ${
                        formState === STATUS.SENT ? "hidden" : "flex"
                    } justify-center`}
                >
                    <HCaptcha
                        sitekey="d054b35e-7f30-4da0-93e5-feb49ffb9e6d"
                        onVerify={(token, ekey) =>
                            handleVerificationSuccess(token, ekey)
                        }
                    />
                </div>

                <button
                    disabled={formState !== STATUS.NOT_SENT}
                    type="submit"
                    className={`${
                        isHuman ? "flex" : "hidden"
                    } items-center justify-center ${
                        formState === STATUS.SENT
                            ? "bg-green-500"
                            : "bg-blue-900"
                    } text-white font-semibold h-12 md:h-20 rounded-xl cursor:pointer w-full text-xl md:text-3xl`}
                >
                    {formState === STATUS.SENDING ? (
                        <ReactLoading
                            type={"bubbles"}
                            color={"#ffffff"}
                            width={50}
                            height={50}
                        />
                    ) : formState === STATUS.SENT ? (
                        <span>Thank you</span>
                    ) : (
                        <span>Send</span>
                    )}
                </button>
            </form>
        </div>
    );
};
export default ContactUsForm;
