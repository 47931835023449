import React from "react";
import Logo from '../../assets/images/embedded-space-footer-colored.png';
import Arrow from "../../assets/icons/rightArrow.svg";
import { motion } from "framer-motion";
import {
    PencilLineIcon,
    LayoutDashboard,    
    BarChart2,
    MailboxIcon,
    LogOutIcon
} from "lucide-react";
import { useState } from "react";
import { setLogout } from "../../components/js-components/utilityFunctions";
import { useNavigate, useParams } from "react-router-dom";
import { PATHS } from "../../components/js-components/globalVariations";

const variants = {
    expanded: { width: "300px" },
    nonExpanded: { width: "85px" },
};

const NavigationBar = () => {
    const subRoute = useParams()["*"];
    const [isExpanded, setIsExpanded] = useState(true);
    const NAVIGATE = useNavigate();

    const navItemClickHandler = (index) => {
        navLinks[index].clickHandler();
    }

    const navLinks = [
        {
            name: "Manage Content",
            icon: PencilLineIcon,
            path: PATHS.editMode,
            clickHandler: () => { NAVIGATE(PATHS.editMode) }
        },
        {
            name: "Dashboard",
            icon: LayoutDashboard,
            path: PATHS.admin.dashboard,
            clickHandler: () => { NAVIGATE(PATHS.admin.root + "/" + PATHS.admin.dashboard) }
        },
        {
            name: "Analytics",
            icon: BarChart2,
            path: PATHS.admin.analytics,
            clickHandler: () => { NAVIGATE(PATHS.admin.root + "/" + PATHS.admin.analytics) }
        },
        {
            name: "Contacts",
            icon: MailboxIcon,
            path: PATHS.admin.contacts,
            clickHandler: () => { NAVIGATE(PATHS.admin.root + "/" + PATHS.admin.contacts) }
        },
        {
            name: "Log Out",
            icon: LogOutIcon,
            path: null,
            clickHandler: () => {
                setLogout();
                window.location.reload();
            }
        }
    ];
    
    return (
        <motion.div
            animate={isExpanded ? "expanded" : "nonExpanded"}
            variants={variants}
            className={`transition-[padding] duration-300 ease-in-out py-12 flex flex-col border border-r-l w-1/5 h-screen relative ${
                isExpanded ? "px-10" : "px-4"
            }`}
        >
            <div
                className={`overflow-hidden logo-div flex space-x-3 items-center ${
                    isExpanded ? "" : "justify-center"
                }`}
            >
                <div>
                    <img alt="Logo" className="max-w-[30px]" src={Logo} />
                </div>
                <span
                    className={
                        "whitespace-nowrap " + (isExpanded ? "block" : "hidden")
                    }
                >
                    Embedded-SPACE
                </span>
            </div>

            <div
                onClick={() => setIsExpanded(!isExpanded)}
                className={`transform translate-x-1/2 ${
                    isExpanded ? "rotate-180" : ""
                } transition-transform duration-500 delay-300 cursor-pointer w-5 h-5 bg-primaryAdmin rounded-full absolute mt-[5px] right-0 flex items-center justify-center`}
            >
                <img alt="Arrow" src={Arrow} className="w-[6px]" />
            </div>

            <div className="mt-12 flex flex-col space-y-8 overflow-hidden">
                {navLinks.map((item, index) => (
                    <div
                        onClick={() => navItemClickHandler(index)}
                        key={index}
                        className={`cursor-pointer flex flex-row items-center ${
                            isExpanded ? "" : "justify-center"
                        } space-x-3 p-2 rounded ${
                            subRoute === item.path
                                ? "bg-primaryAdmin text-white font-semibold"
                                : ""
                        }`}
                    >
                        <div>
                            <item.icon />
                        </div>
                        <span
                            className={
                                "whitespace-nowrap " +
                                (isExpanded ? "block" : "hidden")
                            }
                        >
                            {item?.name}
                        </span>
                    </div>
                ))}
            </div>
        </motion.div>
    );
};

export default NavigationBar;
