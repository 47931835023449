import { useState } from "react";
import { Clipboard, ClipboardCheck } from "react-bootstrap-icons";

const ColoredTextPanel = () => {
    const [isCopied, setIsCopied] = useState(false);
    const [text, setText] = useState("");
    const [selectedColor, setSelectedColor] = useState("#000000");

    const copyHandler = () => {
        const coloredText = `<span style="color: ${selectedColor}">${text}</span>`;
        setIsCopied(copyToClipboard(coloredText));
    };

    const textChangeHandler = (newText) => {
        setText(newText);
        setIsCopied(false);
    }

    const colorChangeHandler = (newColor) => {
        setSelectedColor(newColor);
        setIsCopied(false);
    }

    const copyToClipboard = async (txt) => {
        try {
            await navigator.clipboard.writeText(txt);
            return true;
        } catch (err) {
            return false;
        }
    };

    return (
        <div className="flex flex-row items-center gap-2">
            <button onClick={() => copyHandler()}>
                {isCopied ? (
                    <ClipboardCheck color="#177BD8" size={25} />
                ) : (
                    <Clipboard color="#177BD8" size={25} />
                )}
            </button>
            <input style={{ color: selectedColor }} placeholder="Type..." value={text} onChange={(event) => textChangeHandler(event.currentTarget.value)} className="rounded border-solid border-gray-100 border-2 px-2 py-1 w-2 grow" type="text" />
            <input type="color" value={selectedColor} onChange={(event) => colorChangeHandler(event.currentTarget.value)} />
        </div>
    );
};
export default ColoredTextPanel;
