import React from "react";
import NavigationBar from "./NavigationBar";
import { Outlet } from "react-router-dom";

const Admin = () => {
    return (
        <>
            <div className="w-full flex flex-row">
                <NavigationBar />
                <main className="grow">
                    <Outlet />
                </main>
            </div>
        </>
    );
};

export default Admin;
