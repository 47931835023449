import DesktopNavBar from "./DesktopNavBar";
import MobileNavBar from "./MobileNavBar";
import { v4 as uuidv4 } from 'uuid';
import { useDataContext } from "./DataContext";

const NavBar = () => {
    const { data } = useDataContext();
    const ABOUT_US = data.ABOUT_US;
    const APPLICATIONS = data.APPLICATIONS;
    const SERVICES = data.SERVICES;
    const CONTACT_US = data.CONTACT_US;
    const ITEMS = [
        { key: uuidv4(), text:ABOUT_US.name, href: "#aboutUsSection"},
        { key: uuidv4(), text:APPLICATIONS.name, href: "#applicationsSection"},
        { key: uuidv4(), text:SERVICES.name, href: "#ourServicesSection"},
        { key: uuidv4(), text:CONTACT_US.name, href: "#contactUsSection"}
    ];
    return (
        <div id="#navbar">
            <DesktopNavBar ITEMS={ITEMS} />
            <MobileNavBar ITEMS={ITEMS} />
        </div>
    );
};
export default NavBar;
