import "reactjs-popup/dist/index.css";
import { useDataContext } from "./DataContext";

const ApplicationItem = (props) => {
    let { isEditMode } = useDataContext();

    return (
        <li className="cursor-pointer py-1 overflow-hidden application-item rounded-lg flex items-center justify-center flex-col gap-2">
            <img src={props.image} alt={props.text} className="w-32 md:w-40" />
            <h5
                className="font-medium text-center text text-base md:text-2xl"
                dangerouslySetInnerHTML={{ __html: props.text }}
            />
            {isEditMode ? (
                <button
                    onClick={() => props.deleteApplicationsItem(props.index)}
                    className="text-red-500 font-bold underline"
                >
                    Delete
                </button>
            ) : null}
        </li>
    );
};
export default ApplicationItem;
