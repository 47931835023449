import ApplicationItem from "./ApplicationItem";
import { useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useDataContext } from "./DataContext";
import DropDownImage from "./DropDownImage";
import { NOT_SET_VALUE } from "../js-components/globalVariations";
import ColoredTextPanel from "./ColoredTextPanel";

const SectionApplications = () => {
    const { data, updateData, images, isEditMode } = useDataContext();
    const APPLICATIONS = data.APPLICATIONS;

    let [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
    let [isAddNewPopupOpen, setIsAddNewPopupOpen] = useState(false);
    let [key, setKey] = useState("");
    let [newText, setNewText] = useState("");

    let openPopup = (newKey, text) => {
        setKey(newKey);
        setNewText(text);
        setIsEditPopupOpen(true);
    };

    let updateApplications = () => {
        let newData = { ...data };
        newData.APPLICATIONS[key] = newText;
        updateData(newData);
        setIsEditPopupOpen(false);
    };

    let deleteApplicationsItem = (index) => {
        let res = window.confirm("This item will be deleted !");
        if (!res) return;
        let newData = { ...data };
        newData.APPLICATIONS.items.splice(index, 1);
        updateData(newData);
    };

    let [newItemImage, setNewItemImage] = useState("");
    let [newItemName, setNewItemName] = useState("");
    let addNewItem = (event) => {
        event.preventDefault();
        const NEW_ITEM = {
            image: newItemImage,
            text: newItemName,
        };
        let newData = { ...data };
        newData.APPLICATIONS.items.push(NEW_ITEM);
        updateData(newData);
        setIsAddNewPopupOpen(false);
    };

    return (
        <section id="applicationsSection" className="pt-20 mx-4 md:mx-20">
            <h3
                onClick={
                    isEditMode
                        ? () => openPopup("name", APPLICATIONS.name)
                        : undefined
                }
                className={`${
                    isEditMode ? "editable-content" : ""
                } font-semibold text-center text text-base md:text-3xl`}
                dangerouslySetInnerHTML={{
                    __html: APPLICATIONS.name || NOT_SET_VALUE,
                }}
            />
            <div className="mt-5">
                <p
                    onClick={
                        isEditMode
                            ? () => openPopup("topText", APPLICATIONS.topText)
                            : undefined
                    }
                    className={`${
                        isEditMode ? "editable-content" : ""
                    } text text-center text-sm md:text-xl leading-7 md:leading-10`}
                    dangerouslySetInnerHTML={{
                        __html: APPLICATIONS.topText || NOT_SET_VALUE,
                    }}
                />
                <ul className="mt-10 flex flex-wrap flex-row items-end justify-center gap-x-16 gap-y-5">
                    {APPLICATIONS.items.map((item, index) => (
                        <ApplicationItem
                            deleteApplicationsItem={deleteApplicationsItem}
                            index={index}
                            key={index}
                            text={item.text}
                            image={item.image}
                        />
                    ))}
                    {isEditMode ? (
                        <button
                            onClick={() => setIsAddNewPopupOpen(true)}
                            className="text-red-500 font-bold underline"
                        >
                            + Add New Item
                        </button>
                    ) : null}
                </ul>
            </div>
            <Popup
                className="my-popup"
                open={isEditPopupOpen}
                onClose={() => setIsEditPopupOpen(false)}
            >
                <div className="popup-body p-3 rounded bg-white">
                    <div className="mb-4">
                        <textarea
                            onChange={(event) =>
                                setNewText(event.currentTarget.value)
                            }
                            value={newText}
                            id="message"
                            name="message"
                            className="text text-sm md:text-xl bg-white w-full h-72 p-5 border rounded-xl"
                            rows="4"
                            placeholder="Type..."
                            required
                        ></textarea>
                    </div>
                    <ColoredTextPanel />
                    <div className="m-4 flex justify-end">
                        <button
                            onClick={() => setIsEditPopupOpen(false)}
                            className="px-5 py-2 text-blue-500 hover:opacity-80"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={() => updateApplications()}
                            className="px-5 py-2 bg-blue-500 text-white rounded hover:opacity-80"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Popup>
            <Popup
                className="my-popup"
                open={isAddNewPopupOpen}
                onClose={() => setIsAddNewPopupOpen(false)}
            >
                <div className="popup-body p-3 rounded bg-white">
                    <form className="w-full">
                        <div className="mb-4">
                            <DropDownImage
                                options={images}
                                onOptionSelected={(option) =>
                                    setNewItemImage(option.image)
                                }
                            />
                        </div>

                        <div className="mb-4">
                            <input
                                onChange={(event) =>
                                    setNewItemName(event.currentTarget.value)
                                }
                                value={newItemName}
                                type="text"
                                className="text text-sm md:text-xl bg-white w-full p-5 border rounded-xl"
                                placeholder="Name ..."
                                required
                            />
                        </div>
                        <ColoredTextPanel />
                        <div>
                            <div className="m-4 flex justify-end">
                                <button
                                    onClick={() => setIsAddNewPopupOpen(false)}
                                    className="px-5 py-2 text-blue-500 hover:opacity-80"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={(event) => addNewItem(event)}
                                    type="submit"
                                    className="px-5 py-2 bg-blue-500 text-white rounded hover:opacity-80"
                                >
                                    Add Item
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Popup>
        </section>
    );
};
export default SectionApplications;
